.mat-paginator {
    background-color: transparent;
    .mat-paginator-container {
        min-height: inherit;
    }
    .mat-paginator-page-size-label {
        font-size: 14px;
    }
    .mat-paginator-page-size {
        margin-right: 0;
    }
    .mat-paginator-page-size-select {
        margin: 0 0 0 0.75rem !important;
        width: 100px;
        height: 2.5rem;
        .mat-form-field-wrapper {
            padding-bottom: 0;
            height: 100%;
            .mat-form-field-flex {
                height: 100%;
                .mat-select-value-text {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #BBBBBB;
                    padding-left: 0.5rem;
                }
                .mat-select-arrow {
                    width: 1.25rem;
                }
            }
        }
    }
    .mat-paginator-range-label {
        margin: 0 32px 0 16px;
        font-size: 14px;
    }
    button {
        &.mat-button-disabled {
            box-shadow: none !important;
            background: transparent !important;
            color: rgba(255, 255, 255, 0.3) !important;
            border: none !important;
        }
    }
}