/*Add a New Member*/

.mat-stepper-horizontal, .mat-stepper-vertical{
    background: none;
    .mat-step-text-label {
      font-weight: 400;
      letter-spacing: 0.5px;
      font-size: 13px;
    }
   }
   .mat-datepicker-alignment{
     position: relative;
     button{
       position: absolute;
       right: 4px;
       top: 2px;
       z-index: 1;
     }
   }
   .back-arrow{
       color: #fff!important;
       padding-top: 10px;
       margin-top: 0!important;
       cursor: pointer;
       mat-icon{
         margin-right: 5px;
       }
   }
   .add-new-member-stepper{
     .form-wrapper{
       padding: 1rem 0;
     }
     .mat-horizontal-stepper-header-container{
       width: 564px;
       margin: 0 auto;
     }
     .mat-step-label {
       position: absolute;
       bottom: 0;
       left: 0;
       width: 100%;
       text-align: center;
     }
     .mat-stepper-horizontal-line{
       margin: 0 -25px;
     }
     .form-instruction{
      margin-top: 40px;
      font-size: 17px;
      font-weight: 300;
     }
     .mat-horizontal-content-container {
       overflow: hidden;
       padding: 0;
     }
     .mat-horizontal-stepper-header .mat-step-icon{
       margin-right: 0;
       background: none;
     }
     .mat-step-header .mat-step-icon-selected{
       background: #fff;
     }
     .mat-step-header .mat-step-icon-content{
       font-size: 0;
       height: 16px;
       width: 16px;
       border-radius: 100%;
       border: 1px solid rgb(143, 153, 158);
       position: relative;
       &:after{
         height: 8px;
         width: 8px;
         background: rgba(96, 107, 113, 1); 
         position: absolute;
         display: block;
         content: "";
         border-radius: 100%;
         top: 3px;
         left: 3px;
       }
     }
     .mat-step-header .mat-step-icon-selected .mat-step-icon-content{
       background: #00850700;
       border: 1px solid rgba(143, 153, 158, 0);
       &:after{
         background: #008507;
       }
     }
     .mat-step-header .mat-step-icon-state-edit{
       background: #008507;
       border: 1px solid #fff;
     }
     .mat-step-header .mat-step-icon-state-edit .mat-step-icon-content{
       background: #008507 url("../images/tick.svg") no-repeat!important;
       border: 1px solid rgba(143, 153, 158, 0);
       left: 12px;
       top: 14px;
       &:after{
        display: none;
       }

     }
     .mat-step-icon .mat-icon{
      display: none;
     }
   
   }
   .add-new-member-stepper.add-dependent-details-stepper .mat-step-header .mat-step-icon-content:after{
    top: 3px;
   }
   .button-row{
     border-top: 1px solid rgba(170, 170, 170, 0.5);
     padding-top: 1rem;
     .primary-btn, .secondary-btn {
      min-width: 80px;
     }
   }

   .map-header{
    height: 60px;
    color: $secondary;
    h2{
      font-size: 18px;
      margin-bottom: 0;
    }
    .map-header-tagline{
      font-size: 11px;
    }
   }
   
   .custom-dialog-width{
    display: flex;
    .map-info{
      width: 45%;
      padding-right: 20px;
      label{
        font-size: 12.5px;
        font-weight: 400;
        opacity: 0.6;
      }
      .value{
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 20px;
      }
      h3{
        color: $secondary;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .drug-dialog-heading{
        font-size: 19px;
      }
      .day-time{
        margin-bottom: 6px;
        width: 85px;
        display: inline-flex;
      } 
    }
    .mapouter{
      width: 55%;
      overflow: hidden;
      height: calc(78vh - 80px);
      border-radius: 8px;
    }
   }
.price-breakdown{
  display: flex;
  .price-item{
    width: 33%;
  }
}
.week-block{
  margin-bottom: 0!important;
}
.see-ur-pro{
  font-size: 13px!important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color: #548301;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #53830171;
}
.custom-tab .mat-tab-label {
  height: 2.25rem;
  padding: 0 0.5rem;
}
.files-list{
  ul{
    margin: 0 0 0 5px;
    padding: 0;
    display: flex;
    flex-flow: wrap;
    li{
      padding: 0;
      margin: 0;
      list-style: none;
      a{
        background-image: url("../images/formulary-download.svg");
        background-repeat: no-repeat;
        background-position: left;
        color: $secondary;
        display: inline-block;
        padding: 10px;
        padding-left: 26px;
        padding-right: 20px;
        text-decoration: none;
        background-size: 16px;
        font-weight: 500;
      }
    }
  }
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.img-cell{
  min-width: 70px;
  vertical-align: middle;
  padding-left: 10px!important;
  img{
    margin: 0px 5px;
    height: 16px;
  }
  img.disbled-img{
    opacity: 0.5;
  }
}
@media screen and (max-device-width: 1366px), screen and (max-width: 1366px) {
  .add-new-member-stepper .mat-step-header .mat-step-icon-content:after{
    top: 2.5px;
  }
}
.mat-dialog-container{
	.mat-dialog-title{
    color: $secondary;
    font-size: 18px!important;
    margin-bottom: 7px!important;
  }
}