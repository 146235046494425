.custom-menu {
    &.mat-menu-panel {
        background-color: $primary;
        @include radius(10px);
        .mat-menu-content {
            padding: 0.75rem;
            .mat-menu-item {
                width: 100%;
                height: auto;
                line-height: 1;
                padding: 0.5rem;
                color: #DDDDDD;
            }
        }
    }
}