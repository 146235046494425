/* Abstracts - variables, functions, mixin, placeholders, etc */
@import "abstracts/utilities";
@import "base/base";
@import "layout/layout";
@import "components/components";
@import "pages/pages";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
