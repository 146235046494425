.hero-wrapper {
  width: 50rem;
  padding: 5rem 1rem 5rem;
  max-width: 100%;
  margin: auto;
}
.hero-text {
  padding-right: 2rem;
  //position: fixed;
  width:282px;
 // top:50%;
  //@include transform(translateY(-50%));
  h1 {
    font-size: var(--hero-title-size);
    font-weight: 300;
    line-height: calc(var(--hero-title-size) + 0.5rem);
    color: $tertiary;

    span {
      font-weight: 500;
    }
  }

  p {
    font-size: var(--hero-text-size);
    font-weight: 300;
    line-height: calc(var(--hero-text-size) + 0.5rem);
  }
}

.hero-content {
  padding-left: 2rem;
  padding-top: 0.5rem;
  width: 50rem;
  h4 {
    font-size: var(--label-text-size);
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .input-group {
    mat-checkbox {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .btn-group {
    margin-top: 2rem;
  }
}

.hero-brand {
  height: var(--hero-brand-size);
  margin-bottom: 2rem;
  display: block;
}

.or {
  text-align: center;
  position: relative;
  margin: 1rem 0;
  text-transform: uppercase;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - 1px);
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0rem 0.5rem;
  }
}

// .blur-selection-report {
//   opacity: 0.2;
//   cursor: not-allowed;
// }
.align-radio-box {
  margin-top: 0.625rem;
}
// .custom-logout {
//   position: absolute;
//   right: 1rem;
//   top: 0;
//   height: var(--header-height);
// }
.header{
  padding:0.9375rem;
}
.bottom-panel{
  .btn-group{
    margin-top:0.5rem;
    padding:0.9375rem 0;
   @include box-shadow(0px -5px 10px rgba(0,0,0,0.3));
   position: absolute;
   bottom: 40px;
   width: 100%;
  }
  .secondary-btn{
    height: 40px;
    margin-right: 10px!important;
  }
}
.left-panel{
  // position: sticky;
  // top:7rem;

}




.welcome-contnet{
  width: 50%;
  padding: 3rem 0 3rem 3rem;
  .hero-text-full{
      width: 100%;
      position: static;
      transform: none; 
      -webkit-transform: none; 
  }
}
.welcome-buttons{
  width: 50%;
  padding: 3rem;
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: normal;
  }
  button{
      width: 100%;
      max-width: 350px;
      border: 1px solid #AAAAAA80;
      height: 70px;
      line-height: 70px;
      background: none;
      text-align: left;
      margin: 10px 0;
      box-shadow: none;
      color: white;
      font-size: 18px;
      img{
          height: 22px;
          margin-right: 8px;
          position: relative;
          top: -2px;
      }
  }
  .input-group {
    div div {
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
}
.main-footer {margin: 0 auto!important;}
@media screen and (max-device-width: 840px), screen and (max-width: 840px) {
  .dashboard-content {
      overflow: auto;
  }
}
@media screen and (max-device-width: 767px), screen and (max-width: 767px) {
  .welcome-contnet {
      padding: 0 !important;
      width: 100%;
  }
  .welcome-buttons{ 
      width: 100%;
      padding: 0;
  }
  .bottom-panel{
    .btn-group{ 
      bottom: 60px;
    }
  }
  .report-section {
    .full-width {
      flex-direction: column!important;
    }
  }

}
@media screen and (max-device-width: 520px), screen and (max-width: 520px) {
  .list-of-report {
    display: flex;
    .input-group {
      width: 100%;
    }
  }
}
@media screen and (max-device-width: 767px), screen and (max-width: 767px) {
  .welcome-buttons{ 
      align-items: flex-start !important;
      place-content: flex-start !important;
  }
}
