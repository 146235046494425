button {
  &.mat-icon-button {
    @include icon-size(var(--header-icon-size));
  }
}

.mat-button-base {
  &[rounded] {
    @include radius(2em);
  }

  &.mat-raised-button {
    padding: 0 1.5rem;
  }
}

.mat-primary {
  .mat-option {
    &.mat-selected {
      &:not(.mat-option-disabled) {
        color: inherit;
      }
    }
  }
}

.mat-button.primary-btn{
  background: $primary-btn;
   border-radius: 30px;
   margin: 0 5px;
   font-weight: 400!important;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   min-width: 120px;
 }

 .mat-button.secondary-btn {
    border: 2px solid $secondary;
   background-color: #ffffff;
   border-radius: 30px;
   margin: 0 5px;
   color: $primary-btn-text;
   font-weight: 400!important;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   min-width: 120px;
 }

 .mat-button.secondary-btn.disabled-btn{
  border: 2px solid #AAAAAA;
  color: #aaa;
  pointer-events: none;
 }

 .mat-button.sm-btn {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  min-width: 90px;
 }