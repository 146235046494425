.footer {
  font-size: 0.75rem;

  &.top-spacing {
    padding-top: 0.4rem;
  }

  &.bottom-spacing {
    padding-bottom: 0.4rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .content-left {
    margin-left: 10px;
  }

  .content-right {
    font-weight: 700;
    color: $secondary;
  }
}
