// Transform
@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
}

// Rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Radius
@mixin radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

// Transitions
@mixin transition($value) {
  transition: $value;
  -moz-transition: $value;
  -webkit-transition: $value;
}

// Box Shadow
@mixin box-shadow($value) {
  box-shadow: $value;
  -moz-box-shadow: $value;
  -webkit-box-shadow: $value;
}

// Icon Size
@mixin icon-size($value) {
  height: $value;
  width: $value;
  line-height: $value;
}

// box Size
@mixin box-size($value) {
  height: $value;
  width: $value;
}

// text-wrap
@mixin text-wrap {
  text-overflow: unset;
  white-space: normal;
  line-height: normal;
  overflow: unset;
}
