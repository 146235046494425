// Overflow
.overflow-auto {
  overflow: auto;
  scroll-behavior: smooth;
}
.overflow-hidden {
  overflow: hidden;
}

// Alignment
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

// box-height-width
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}
.loader-img {
  @include box-size(var(--loader-size));
  display: block;
}
