// Checkbox

.mat-checkbox {
  .mat-checkbox-layout {
    white-space: normal;
    align-items: flex-start;
  }
  .mat-checkbox-inner-container {
    margin: 0;
    margin-top: 1px;
    margin-right: 8px;
  }
}

// Radio

mat-radio-group {
  mat-radio-button {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}
.search-block{
  position: relative;
  mat-icon{
    position: absolute;
    top:0.375rem;
    left:0.625rem;
  }
  input{
    font-size: 1rem;
    border-radius: 20px;
    padding: 0 .5rem 0 2.5rem;
    color: #fff;
    background: #04223a;
    border: none;
    height: 2rem;
    &:focus,
    &:focus-visible {
    border:none;
      outline: none;
    }
  }
}
.search-input-block{
  position: relative;
  img{
    position: absolute;
    top:0.5rem;
    left:0.625rem;
  }
  input{
    font-size: 13px;
    border-radius: 10px;
    padding: 10px 10px 10px 2.0rem;
    color: #fff;
    background: rgb(255 255 255 / 10%);
    border: none;
    width: 230px;
    //height: 2rem;
    &:focus,
    &:focus-visible {
    border:none;
      outline: none;
    }
    &::placeholder {
      color: #BBBBBB;
      opacity: 1;
    }
  }
}

//
.custom-radio{
  p{
    position:relative;
    & + p{
      left:-1.5625rem;
    }
  }
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
      width:225px;
      height:32px;
      display:flex;
      align-items: center;
      justify-content: center;
      //@include radius(1.25rem);
      //border:1px solid red;
      //@include font(0.9375rem,500,$dark-blue);
      cursor:pointer;
  }
  [type="radio"]:not(:checked) + label {
    background:#f5f5f5;
  }
  [type="radio"]:checked + label{
    background:linear-gradient(100.78deg, #2B826C 21%, #3ABA98 105.8%);
    border:0;
    color:white;
    position:relative;
    z-index: 1;
    //@include font(0.9375rem,700);
  }
}
