mat-dialog-container.mat-dialog-container {
  padding: var(--modal-outer-spacing);

  .mat-dialog-content {
    margin: 0 calc(var(--modal-outer-spacing) * -1);
    padding: 0 var(--modal-inner-spacing);
  }
  .mat-dialog-actions {
    padding: 0;
    margin: 0;
    min-height: 0;
  }
}

.mat-dialog-container {
  background: $primary;
}
.mail-order-modalbox {
  overflow: auto;
}
.update-coverage-details-modalbox {
  height: 580px;
  width: 800px;
  @media screen and (max-device-width: 768px), screen and (max-width: 768px) {
    height: 500px;
  }
}
.add-coverage-types-modalbox {
  width: 980px;
}