.mat-toolbar {
  min-height: var(--header-height);
  box-shadow: var(--header-shadow);
  position: relative;
  z-index: 1;

  .mat-toolbar-row {
    padding: var(--header-inner-spacing);
    height: var(--header-height);
  }

  .mat-icon-button {
    @include icon-size(var(--header-icon-size));
  }

  .brand {
    max-height: var(--brand-height);
    margin-left: 1rem;
    display: block;
  }
}

.header-title {
  font-size: var(--header-title-size);
  margin-right: 1rem;
  font-weight: 500;
  &.client-name {
    color: var(--client-name-text-color);
    max-width: 15.625rem;
    line-height: 1.6;
    white-space: normal;
    text-align: right;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: unset;
    //font-size: calc(var(--header-title-size) + 4px);
  }
}
