.static-content {
  padding-bottom: 3rem;
  min-height: calc(100% - 3.25rem);

  .mat-expansion-panel {
    p,
    li {
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.6;
      text-align: justify;
    }

    .static-subtitle {
      font-size: 1rem;
      font-weight: 500;
      text-transform: capitalize;
    }

    a {
      color: var(--client-name-text-color);
      font-weight: 400;
    }
  }
}

.container {
  width: 970px;
  max-width: 100%;
  margin: 0 auto;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #548301;
}

.static-header {
  .header-inner {
    padding-bottom: 1rem;
    padding-top: 1.5rem;
  }

  .static-title {
    margin: 0;
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
    border-left-width: 3px;
    border-left-style: solid;
    padding: 0.625rem 0;
    padding-left: 1rem;
  }
}

.static-text {
  margin-top: 1rem;

  p,
  li {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.6;
    text-align: justify;
  }

  li {
    margin-bottom: 0.3125rem;
  }

  a {
    color: var(--client-name-text-color);
    font-weight: 400;
  }
}

.static-brand {
  img {
    display: block;
    width: 6.25rem;
  }
}

// Who we are

.who-we-are-content {
  padding: 2rem;
  background: #fff;
  margin-top: 1rem;
  color: #000;

  .secondary-sub-header {
    font-size: 1.375rem;
    font-weight: 500;
    color: $secondary;
    margin-bottom: 0.625rem;
  }

  .text-content {
    & + .text-content {
      margin-top: 2rem;
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;

    li {
      position: relative;
      padding-left: 1rem;

      &:before {
        content: "";
        @include box-size(7px);
        @include radius(50%);
        position: absolute;
        left: 0;
        top: 5px;
        background: $secondary;
      }
      & + li {
        margin-top: 0.5rem;
      }
    }
  }

  .left-content {
    padding-right: 2rem;
  }

  .mat-divider {
    border-top-color: $secondary;
  }

  strong {
    font-weight: 500;
  }
}

.who-we-are-quote {
  text-align: right;
  position: relative;
  padding: 1rem 0;
  background: url("../images/quote-icon.png") no-repeat;
  background-position: 10px 10px;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }

  i {
    color: $secondary;
  }
}

.industry-recognition-list {
  margin-top: 1rem;
  a {
    display: block;
    padding: 0 1rem;

    img {
      display: block;
      max-width: 6.25rem;
    }
  }
}

.result-chart {
  height: 100%;
  background: #ffffff;
  background: -moz-linear-gradient(top, #ffffff 1%, #efefef 100%);
  background: -webkit-linear-gradient(top, #ffffff 1%, #efefef 100%);
  background: linear-gradient(to bottom, #ffffff 1%, #efefef 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efefef',GradientType=0 );
  width: 100%;
}

.result-thumbnail {
  padding: 1rem;
  img {
    width: 100%;
  }
}

.value-based-image {
  margin-bottom: 2rem;
  img {
    display: block;
    margin: 0 auto;
  }
}
.modal-header {
  color: $secondary;
  font-size: 18px !important;
  margin-bottom: 7px !important;
}
/*Page wrapper*/
.page-wrapper {
  background: rgba($primary, 20%);
}

.page-wrapper,
.mat-dialog-container {
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);
  padding: 1.75rem 1.25rem;
  border-radius: 10px 10px;
  overflow: auto;
  height: calc(100% - 60px);
  @media screen and (max-device-width: 700px), screen and (max-width: 700px) {
    height: calc(100% - 75px);
    width: 100% !important;
    overflow-y: scroll!important;
  }
  .mat-form-field-wrapper .mat-form-field-flex {
    background:rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-infix {
    padding: 10px;
    border-top: 0;
    width: auto;
  }
  .mat-form-field-label-wrapper {
    top: -10px;
    width: calc(100% - 10px);
    overflow: visible;
  }
  .mat-form-field-label {
    padding-left: 10px;
  }
  select.mat-input-element {
    padding-top: 0;
    top: 0;
    margin-bottom: 0;
    box-shadow: none !important;
  }
  .mat-form-field-label {
    top: 1.34375em;
  }
  .mat-form-field {
    margin-top: 5px;
  }
  .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    right: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #3a4055;
    z-index: 2;
  }
  .mat-form-field.full-width.ng-touched select.mat-input-element {
    box-shadow: none !important;
    border-bottom: 0;
  }
  .mat-form-field-type-mat-native-select .mat-form-field-infix::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #b6bac7;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1.5px;
    pointer-events: none;
    right: 10px;
    z-index: 1;
  }
  .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    color: #3a4055;
  }
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0.5rem;
  }
  .mat-form-field-type-mat-native-select.mat-form-field-invalid
    .mat-form-field-infix::after {
    border-top: 8px solid #242a42;
  }
}

/* pages */
.page-header {
  padding: 0;
  position: relative;
  .add-new-member {
    position: absolute;
    top: 0;
    right: 0;
  }
  h2 {
    margin-bottom: 5px;
    color: $secondary;
  }
  p {
    font-weight: 300;
  }
}
.text-right {
  text-align: right !important;
}

/* form fields */
.form-wrapper {
  padding: 0.5rem 0;
  .mat-row {
    display: flex;
  }
}
.tab-action-group.mat-row {
  display: flex;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #fff !important;
}
.full-width {
  width: 100%;
}

.mat-12 {
  width: 100%;
}
.mat-10 {
  width: 80%;
}
.mat-8 {
  width: 60%;
}
.mat-6 {
  width: 50%;
}
.mat-4 {
  width: 40%;
}
.mat-4-25 {
  width: 25%;
}
.mat-2 {
  width: 20%;
}
.mat-9 {
  width: 66%;
}
.mat-3 {
  width: 34%;
}
.mat-33 {
  width: 33%;
}
.pad-b-0 {
  padding-bottom: 0 !important;
}
.pad-t-1 {
  padding-top: 1rem !important;
}
.mar-b-0 {
  margin-bottom: 0 !important;
}
.mar-b-half {
  margin-bottom: 0.5rem !important;
}
.mar-t-0 {
  margin-top: 0rem !important;
}
.pad-t-0 {
  padding-top: 0rem !important;
}
.mar-t-1 {
  margin-top: 1rem !important;
}
.mar-b-1 {
  margin-bottom: 1rem !important;
}
.mar-b-15 {
  margin-bottom: 1.5rem !important;
}
.pad-b-1 {
  padding-bottom: 1rem !important;
}
.pad-b-2 {
  padding-bottom: 2rem !important;
}
.pad-b-half {
  margin-bottom: 0.5rem;
}
.pad-r-1 {
  padding-right: 1rem !important;
}
.pad-r-2 {
  padding-right: 2rem;
}
.pad-1 {
  padding: 1rem;
}
.pad-half {
  padding: 0.5rem;
}
.mat-typography p.pad-b-half {
  margin-bottom: 0.5rem;
}
.top-label-space {
  margin-top: 25px !important;
}
.bold-text {
  font-weight: 500;
}
.underline-text {
  text-decoration: underline;
}
.d-block {
  display: block;
}
a.d-block {
  cursor: pointer;
}
.radio-label {
  display: block;
  padding-bottom: 0.65rem;
}
.no-border-btn {
  border: 0 !important;
}
.no-border-btn.mat-button-disabled {
  border: 0 !important;
  background: none !important;
  box-shadow: none !important;
  color: $secondary !important;
}
.serach-btn-top-spacing {
  padding-top: 55px;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: calc(100% + 16px);
    height: 1px;
    background: #ffffff00;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    top: 36px;
    left: -16px;
  }
}
.clear-both {
  clear: both;
  overflow: hidden;
}
.three-column {
  width: 33%;
  float: left;
  padding: 2px;
  padding-right: 30px;
  @media screen and (max-device-width: 480px), screen and (max-width: 480px){
    width: 100%!important;
  }
  @media screen and (min-device-width: 481px) and (max-device-width: 767px), 
    screen and (min-device-width: 481px) and (max-width: 767px) {
      width: 50%;
      margin-bottom: 10px;
      word-break: break-word;
  }
}
.sec-heading {
  font-size: 16px !important;
  margin: 20px 0 10px !important;
  font-weight: 500 !important;
}
.agm-map-container-inner {
  border-radius: 10px;
}
.clickable-row td {
  cursor: pointer;
}
// .clickable-row tr td:first-child{
//   cursor: default;
// }
.clickable-row tr.no-data-row td:first-child {
  cursor: default;
}

/* page detail section */
.detail-information {
  padding: 0.5rem 1rem;
}
.close-icon {
  position: relative;
  top: 5px;
  cursor: pointer;
}
.table-td-spacing td p {
  margin: 0;
}
.drug-note .mat-icon,
.table-td-spacing td .mat-icon {
  border-radius: 100%;
  border: 1px solid $secondary;
  height: 16px;
  width: 16px;
  font-size: 10px;
  text-align: center;
  padding-top: 2px;
  color: $secondary;
}
.drug-note .mat-icon {
  margin-right: 4px;
}
.light-gray-color {
  color: #aaa;
}
.tabs-information {
  padding: 1rem;
  .mat-tab-label {
    height: 48px;
    padding: 0 16px;
  }
  .mat-tab-group {
    border: 1px solid #676a76;
    border-radius: 10px;
  }
  .mat-tab-label {
    font-weight: 400;
  }
  .mat-tab-label {
    font-weight: 400;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $secondary;
  }
}
.programs-list {
  .mat-icon {
    height: 18px;
    width: 22px;
  }
}
.programs-icons img {
  height: 16px;
  position: relative;
  top: 2px;
}
.mat-table {
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: #548301;
    border-radius: 4px !important;
  }
}
.mat-checkbox-checkmark {
  top: 1px !important;
  left: 1px !important;
  width: 85% !important;
}
.mat-checkbox-mixedmark {
  background-color: #ffffff;
}
.mat-checkbox-frame {
  border-radius: 4px !important;
}
.detail-card {
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0.75rem 1rem;
  .mat-row {
    display: flex;
  }
  h4 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 5px;
    padding-right: 5px;
  }
}
.card-label {
  color: #ffffff7a;
  font-size: 14px;
}
.see-more-accordian
  .dependents-accordion
  .mat-expansion-panel
  .mat-expansion-panel-header {
  background: rgba(255, 255, 255, 0.04);
}
h3.form-heading {
  font-size: 16px;
  margin: 1rem 0;
  font-weight: 500;
}
.add-new-member-stepper.add-dependent-details-stepper
  .mat-horizontal-stepper-header-container {
  width: 230px;
  margin-top: -1.5rem;
}
.back-arrow-inner {
  position: relative;
  top: -5px;
  .mat-icon {
    position: relative;
    top: 7px;
    left: -2px;
  }
}
.mat-button-disabled {
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1) !important;
  background: rgba(170, 170, 170, 1) !important;
  color: #fff !important;
  border: 2px solid rgba(170, 170, 170, 1) !important;
}
.mat-autocomplete-panel {
  background: #0B4060;
}
.mat-dialog-container {
  .dialog-btn-row {
    padding-top: 10px;
  }
}
mat-datepicker-toggle .mat-button-disabled {
  border: 0 !important;
  box-shadow: none !important;
  background: none !important;
  opacity: 0.5;
}
.tr-btn-icon {
  cursor: pointer;
  font-size: 16px;
  height: 16px;
  width: 16px;
}
.mat-table,
.mat-elevation-z8 {
  border: 1px solid rgba(109, 112, 124, 1);
  border-radius: 10px;
  overflow: hidden;
  th {
    background: #032238;
    font-size: 13px;
  }
  tr:nth-child(even) {
    background: #0f3752;
  }
  tr:nth-child(odd) {
    background: #052f4a;
  }
}

/***/
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
  //border: 1px solid $error;
  border: 1px solid transparent;
  border-radius: 10px;
  line-height: 34px;
  top: 10px;
  left: 0;
  width: calc(100% + 9px);
  padding-left: 10px;
  transition: none;
  background: rgba(255, 255, 255, 0);
  //background: #242a42;
}
.mat-select-arrow-wrapper {
  position: relative;
  z-index: 1;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.text-danger {
  color: #FF8605 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: rgba(84, 131, 1, 1);
}
.mat-radio-button .mat-radio-ripple {
  display: none;
}
.mat-step-header .mat-step-header-ripple {
  display: none;
}
.mat-step-header:hover {
  background-color: rgb(255 255 255 / 0%);
}
.address-card {
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 5px;
  margin: 1rem 0;
  padding: 0.75rem 2rem 0.75rem 1rem;
  position: relative;
  mat-icon {
    position: absolute;
    top: 12px;
    right: 9px;
    cursor: pointer;
  }
}
.mat-select-arrow {
  background: url("../images/select-dropdown.svg") no-repeat;
  width: 18px !important;
  height: 10px !important;
  background-size: cover;
  border: 0 !important;
}
.mat-datepicker-toggle .mat-button-wrapper {
  background: url("../images/date-picker.svg") no-repeat;
  height: 20px;
  width: 20px;
  display: block;
  left: 7px;
  position: relative;
  svg {
    display: none;
  }
}

.search-line {
  display: flex;
  padding: 20px 0 15px;
  font-size: 15px !important;
  margin-bottom: 0 !important;
  span {
    padding-right: 20px;
    font-weight: 500;
    .number-id {
      font-weight: 500;
    }
  }
  span:first-child {
    font-weight: 400;
    color: #ffffffd0;
  }
}
.disabled-control {
  opacity: 0.4;
}
.toggle-buttons {
  border: 1px solid #022741!important;
  border-radius: 10px;
  .mat-button-toggle-label-content {
    line-height: 36px;
  }
  .mat-button-toggle {
    background: #052f4a;
    color: #aaa;
  }
  .mat-button-toggle.mat-button-toggle-checked {
    background:#022741;
    color: #fff;
  }
}
.close-icon-dialog {
  float: right;
  color: #fff;
  cursor: pointer;
}
.filter-box {
  background: #0B4060;
  padding: 5px 15px;
  width: 220px;
  .filter-heading {
    font-size: 16px;
    position: relative;
    line-height: 34px;
    .mat-icon {
      position: absolute;
      right: 0;
      cursor: pointer;
      top: 0;
    }
  }
  button {
    width: 100%;
    margin-top: 1rem !important;
  }
  .slide-toggle-field {
    padding: 10px 0;
    font-size: 14px;
    color: rgba(221, 221, 221, 1);
    mat-slide-toggle {
      float: right;
      position: relative;
      top: -2px;
    }
  }
}
.sec-heading-no-margin {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.add-new-member-wrapper {
  min-height: calc(100vh - 392px);
}
.update-member-wrapper {
  min-height: calc(100vh - 492px);
}

.claim-search-compare {
  margin: 1rem 0;
  .mat-accordion:nth-child(even) {
    .mat-expansion-panel .mat-expansion-panel-header {
      background:rgba(255, 255, 255, 0.04);
    }
    .mat-expansion-panel-content {
      background: #10273f;
    }
  }
}
th.mat-header-cell {
  color: #fff !important;
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding-right: 12px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: left !important;
}
.example-detail-row td.mat-cell {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
td.mat-cell {
  font-size: 13px;
}
.clickable-row tbody .mat-row:hover {
  opacity: 0.7;
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.footer {
  background-color: $primary;
  height: 44px;
  margin-left: -16px;
  margin-right: -16px;
  padding: 10px 12px;
  border-top: 1px solid #3261A5;
  position: fixed;
  width: 100%;
  bottom: 0;
  .content-right {
    font-weight: 500 !important;
    margin-right: 255px!important;
  }
  @media screen and (max-device-width: 767px), screen and (max-width: 767px) { 
    height: auto;
    position: fixed;
    width: 100%;
    bottom: 0;
    .flex {
      flex-direction: column;
    }
    .content-left {
      margin-left: 0 !important;
    }
    .content-right {
      margin-right: 0 !important;
    }
  }
  // @media screen and (min-device-width: 768px) and (max-device-width: 1300px), 
  //   screen and (min-device-width: 768px) and (max-width: 1300px) {
  //     .content-right {
  //       margin-right: 70px!important;
  //     }
  //   }
}
.closed .dashboard-sidenav {
  .down-arrow {
    display: none;
  }
  .mat-nav-list .mat-list-item .mat-list-item-content .mat-list-icon {
    width: 50px;
  }
}
.mat-datepicker-content {
  background-color:$primary;
}
.zero-padding {
  > .mat-form-field-wrapper {
    > .mat-form-field-flex {
      padding: 0 !important;
    }
  }
}
.add-user {
  background: url("../images/add-icon.svg") no-repeat;
  background-size: 16px 16px;
  background-position: center center;
  margin-top: 5px;
  margin-right: 3px;
  cursor: pointer;
}
.edit-user {
  background: url("../images/edit-icon.svg") no-repeat;
  background-size: 16px 16px;
  background-position: center center;
  margin-top: 5px;
  cursor: pointer;
}
.charge-ship-off{
  background: url("../images/ChargeShip-off.svg") no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.charge-ship-on{
  background: url("../images/ChargeShip-on.svg") no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.reminder-off{
  background: url("../images/reminder-off.svg") no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.reminder-on{
  background: url("../images/reminder-on.svg") no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.mat-calendar-previous-button,
.mat-calendar-next-button {
  position: relative;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
.info-margin {
  margin: 0 -1rem;
}
.mat-tab-body {
  .read-only {
    margin-bottom: 0;
    p {
      font-size: 0.8rem;
      opacity: 0.6;
    }
  }
}
.print-card-wrapper {
  margin-bottom: 20px;
  span {
    display: inline-block;
    border: 2px dashed #aaa;
    padding: 10px;
    padding-bottom: 5px;
    border-radius: 10px;
    img {
      max-width: 1000px;
      width: 100%;
    }
  }
}
.print-table {
  border: 1px solid #000 !important;
  th,
  td {
    border: 1px solid #000 !important;
    font-family: Arial, Helvetica, sans-serif;
  }
}
@media print {
  .top-header,
  .footer,
  .dashboard-sidenav,
  .detail-card,
  .back-arrow-inner,
  .page-header h2,
  h3.form-heading,
  .mat-divider,
  .button-row,
  .mat-button {
    display: none !important;
  }
  .page-wrapper {
    box-shadow: none;
  }
  .print-card-wrapper span {
    border: 0;
    padding: 0;
  }
  table {
    border: 1px solid #000 !important;
  }
  th,
  td {
    border: 1px solid #000 !important;
    font-family: Arial, Helvetica, sans-serif;
  }
  .print-table {
    border: 1px solid #000 !important;
    th,
    td {
      border: 1px solid #000 !important;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  @page {
    size: auto;
    margin: 0mm;
  }
}
.mat-input-element {
  caret-color: #ffffff !important;
}
.mat-select-panel-wrap {
  .mat-select-panel {
    min-width: calc(100% + 20px) !important;
    transform: scaleY(1);
    //margin-top: 40px;
    margin-left: 6px;
  }
}
.mat-option-multiple {
  margin-right: -30px;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
  .mat-select-panel-wrap {
    .mat-pseudo-checkbox::after {
      color: #dbdbdb;
      left: 2px;
    }
    .mat-pseudo-checkbox {
      border: 1px solid #ccc !important;
    }
  }
}

.cdk-overlay-container div:nth-child(4) {
  .mat-select-panel {
    margin-left: 10px !important;
  }
}

.plan-type-select {
  .mat-select-arrow {
    background: none;
  }
  margin-bottom: 15px;
}

.detail-information h2 {
  margin-bottom: 10px !important;
  font-size: 18px;
}
.mat-button-base:hover {
  opacity: 0.8 !important;
}
.item-list {
  padding: 0;
  margin: 0;
  margin-left: 20px;
  li {
    word-wrap: break-word;
    text-wrap: wrap;
  }
}

.dashboard-wrapper.closed {
  .text-label.back-arrow {
    font-size: 0;
  }
  .mat-list-text {
    font-size: 0;
    .mat-line {
      font-size: 0;
    }
  }
  .mat-list-item {
    font-size: 0;
    .mat-line {
      font-size: 0;
    }
  }
}
.dashboard-sidenav .submenu mat-nav-list {
  width: 250px;
}
.dashboard-sidenav .mat-nav-list .mat-list-item.selected .mat-line {
  width: 160px;
}
.dashboard-sidenav .mat-list-base .mat-subheader {
  width: 250px;
}
.goto-link {
  position: absolute;
  bottom: 0;
  height: 30px;
  padding-left: 45px;
  a {
    color: #fff;
    font-size: 12px;
  }
}
.closed {
  .goto-link {
    padding-left: 8px;
    width: 50px;
    height: 37px;
    line-height: 14px;
  }
}
.mat-tooltip {
  background: #dddddd;
  color: #000 !important;
}
.mat-step-header .mat-step-header-ripple {
  visibility: hidden;
  display: none;
  opacity: 0;
}
.mat-step-header {
  &.cdk-keyboard-focused,
  &.cdk-program-focused,
  &:hover {
    background-color: #00000000;
  }
}
.asterisk-mark {
  color: $tertiary;
}
.relative-action {
  position: relative;
  z-index: 11;
}

@media screen and (max-device-width: 800px), screen and (max-width: 800px) { 
  .add-new-member {
    position: relative !important;
    margin: 10px 0 !important;
  }
  .tab-action-group.mat-row {
    flex-wrap: wrap;
    .mat-3 {
      width: 100%;
      padding: 0 !important;
    }
    .mat-4-25 {
      width: 40%;
    }
  }
}
@media screen and (min-device-width: 801px) and (max-device-width: 1275px), 
    screen and (min-device-width: 801px) and (max-width: 1275px) {
      .tab-action-group.mat-row {
        flex-wrap: wrap;
        .mat-4-25 {
          width: 30%;
        }
      }
 }

.client-id-name {
  display: flex;
  span {
    font-size: 14px;
    color: #96A5B0;
    font-size: 13px;
    color: #96A5B0;
    margin-left: 5px;
    margin-top: 2px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 14px;
  }
}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: rgba(255, 255, 255, 0.1)!important;
}


/// Safari browser autofill 
input:-webkit-autofill, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #000;
  -webkit-background-color:rgba(255, 255, 255, 0.10);
}

::ng-deep .mat-button-focus-overlay {
  background-color: white!important;
}

// .mat-select-panel {
//  // position: relative !important; /* Ensure relative positioning */
//   top: -10px !important; /* Reset the top position */
//   transform: none !important; /* Disable any transformation applied */
// }
/* Custom class for the select panel */
.custom-select-panel {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto; /* Allow vertical scrolling */
}
// .cdk-overlay-container .cdk-overlay-pane .custom-select-panel{
//   margin-top: -70px;
//   }
.custom-select-panel div.mat-select-search-inner {
  margin-top: -10px;
  border-radius: 4px 4px 0 0;
  display: block;
  -webkit-border-radius: 4px 4px 0 0;
  // .mat-select-search-input{
  //   height: 4em !important;
  //   }
}

// .custom-select-panel div.mat-select-search-inner .mat-select-search-input{
//   height: 4em !important;
//   }

/* Safari-specific fix */
@supports (-webkit-appearance: none) and (stroke-color: transparent) {
  .mat-select-panel {
      position: absolute !important; /* or fixed, depending on your layout */
      top: 0 !important;             /* Adjust the top position to avoid scrolling */
      left: 0 !important;
      z-index: 1000 !important;
      transform: none !important;    /* Ensure no parent transform affects it */
  }
}
a.view-file-eob {
  background-image: url("../images/icon/icon-view-pdf.svg");
  background-repeat: no-repeat;
  background-position: left;
  color: $secondary;
  display: inline-block;
  padding: 10px;
  padding-left: 26px;
  padding-right: 20px;
  text-decoration: none;
  background-size: 20px;
  font-weight: 500;
  margin-top: 22px;
  margin-left: 20px;
  @media screen and (max-device-width: 580px), screen and (max-width: 580px) {
    margin: 0;
  }
}