.dashboard-sidenav {
  width: var(--sidenav-width);
  min-width: var(--sidenav-width);
  box-shadow: var(--sidenav-shadow);
  @include transition(200ms ease-in-out 0s);
  overflow-x: hidden;

  .mat-list-base .mat-subheader {
    height: 68px;
    line-height: 16px;
    border-bottom: 1px solid #AAAAAA80;
    color: $secondary;
    font-size: 14px;
    font-weight: 400;
    &.back-arrow mat-icon {
      margin-right: 10px;
    }
  }
  .mat-nav-list {
    box-sizing: border-box;
    padding: 0;
    .mat-list-item {
      height: var(--sidenav-nav-height) !important;
      .mat-line {
        font-size: var(--sidenav-text-size);
        line-height: calc(var(--sidenav-text-size) + 4px) !important;
        font-weight: 400;
        @include text-wrap;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .mat-list-item-content {
        padding: var(--sidenav-inner-spacing);
        position: relative;
        .mat-list-icon {
          padding: 0;
          @include icon-size(var(--sidenav-icon-size));
          text-align: center;
          position: absolute;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 0.5rem;
        }
      }
      &.selected {
        .mat-line {
          font-weight: 500;
        }
      }
      &:hover {
        background: $primary;
        color: $secondary;
      }
    }
  }
  .mat-list {
    padding-top: 0;
  }

  .mat-subheader {
    font-size: var(--label-text-size);
  }
}

.closed {
  .dashboard-sidenav {
    width: var(--sidenav-closed-width);
    min-width: var(--sidenav-closed-width);
  }
}

.submenu {
  .mat-nav-list {
    .mat-list-item {
      height: var(--sidenav-subnav-height) !important;

      .mat-list-item-content {
        padding-left: var(--sidenav-subnav-indent);
      }
      .mat-line {
        font-size: var(--sidenav-subnav-text-size);
        line-height: calc(var(--sidenav-subnav-text-size) + 4px) !important;
      }
    }
  }

  .down-arrow {
    @include transition(300ms ease-in 0s);
  }

  &.open {
    .down-arrow {
      @include rotate(180);
    }
  }
}

.benefit-tools .mat-icon.mat-list-icon{
  background: url("../images/benefits.svg") no-repeat;
  background-position: center;
}
.benefit-tools.selected .mat-icon.mat-list-icon{
  background: url("../images/benefits-selected.svg") no-repeat;
  background-position: center;
}
.audit-logs .mat-icon.mat-list-icon{
  background: url("../images/audit-log.svg") no-repeat;
  background-position: center;
  background-size: 16px;
}
.audit-logs.selected .mat-icon.mat-list-icon{
  background: url("../images/audit-log-selected.svg") no-repeat;
  background-position: center;
  background-size: 16px;
}