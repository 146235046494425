@font-face {
  font-family: "empirx-Icon";
  src: url("../fonts/empirx-Icon.eot?misbd8");
  src: url("../fonts/empirx-Icon.eot?misbd8#iefix") format("embedded-opentype"),
    url("../fonts/empirx-Icon.ttf?misbd8") format("truetype"),
    url("../fonts/empirx-Icon.woff?misbd8") format("woff"),
    url("../fonts/empirx-Icon.svg?misbd8#empirx-Icon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="empirx-icon"],
[class*=" empirx-icon"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "empirx-Icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.empirx-iconfinancial-document:before {
  content: "\e90b";
}
.empirx-iconcovid-analytics:before {
  content: "\e902";
}
.empirx-iconclaims-data:before {
  content: "\e900";
}
.empirx-iconcost-drivers:before {
  content: "\e901";
}
.empirx-icondemographics:before {
  content: "\e903";
}
.empirx-icondrugs-category:before {
  content: "\e904";
}
.empirx-iconeligibility-details:before {
  content: "\e905";
}
.empirx-iconeligibility-summary:before {
  content: "\e906";
}
.empirx-iconfinancial-summary:before {
  content: "\e907";
}
.empirx-iconkey-statistics:before {
  content: "\e908";
}
.empirx-iconmembers:before {
  content: "\e909";
}
.empirx-iconmonthly-report:before {
  content: "\e90a";
}
.empirx-iconterms-conditions:before {
  content: "\e90c";
}
.empirx-icontop-20-drugs-analysis:before {
  content: "\e90d";
}
.empirx-icontop-20-drugs-classes:before {
  content: "\e90e";
}
.empirx-icontop-20-drugs-groups:before {
  content: "\e90f";
}
.empirx-icontop-20-drugs-names:before {
  content: "\e910";
}
.empirx-icontop-utilizers:before {
  content: "\e911";
}
.empirx-iconutilization-drugs-type:before {
  content: "\e912";
}
.empirx-iconutilization-polypharmacy:before {
  content: "\e913";
}
.empirx-iconvaccine-trends:before {
  content: "\e914";
}
