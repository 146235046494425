@import "~@angular/material/theming";
@include mat-core();

$mat-primary: (
  50: #e5e6e9,
  100: #bfc1c8,
  200: #9497a3,
  300: #696d7e,
  400: #494e63,
  500: #0B4060,
  600: #242a40,
  700: #1f2337,
  800: #191d2f,
  900: #0f1220,
  A100: #627aff,
  A200: #2f4fff,
  A400: #0027fb,
  A700: #0023e1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-secondary: (
  50: #feebe8,
  100: #fdcec5,
  200: #fcad9f,
  300: #fa8c78,
  400: #f9735b,
  500: #40C09E,
  600: #f75238,
  700: #f64830,
  800: #f53f28,
  900: #f32e1b,
  A100: #ffffff,
  A200: #fff4f3,
  A400: #ffc5c0,
  A700: #ffada7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-medium-purple: (
  50: #edeaf3,
  100: #d2cbe0,
  200: #b4a9cc,
  300: #9687b8,
  400: #806da8,
  500: #695399,
  600: #614c91,
  700: #564286,
  800: #4c397c,
  900: #3b296b,
  A100: #c4b1ff,
  A200: #9e7eff,
  A400: #784bff,
  A700: #6531ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-medium-aqua: (
  50: #e0f5f9,
  100: #b3e5f0,
  200: #80d4e7,
  300: #4dc3dd,
  400: #26b6d5,
  500: #00a9ce,
  600: #00a2c9,
  700: #0098c2,
  800: #008fbc,
  900: #007eb0,
  A100: #daf3ff,
  A200: #a7e2ff,
  A400: #74d2ff,
  A700: #5ac9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-lighter-aqua: (
  50: #e3f8ff,
  100: #baedff,
  200: #8ce2ff,
  300: #5dd6fe,
  400: #3bcdfe,
  500: #18c4fe,
  600: #15befe,
  700: #11b6fe,
  800: #0eaffe,
  900: #08a2fd,
  A100: #ffffff,
  A200: #f2faff,
  A400: #bfe5ff,
  A700: #a6daff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-medium-blue: (
  50: #e6ecf4,
  100: #c2d0e4,
  200: #99b0d2,
  300: #7090c0,
  400: #5179b3,
  500: #3261a5,
  600: #2d599d,
  700: #264f93,
  800: #1f458a,
  900: #133379,
  A100: #aec5ff,
  A200: #7ba0ff,
  A400: #487bff,
  A700: #2f69ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-green: (
  50: #ebf1e0,
  100: #cdddb3,
  200: #acc680,
  300: #8baf4d,
  400: #729d26,
  500: #598c00,
  600: #518400,
  700: #487900,
  800: #3e6f00,
  900: #2e5c00,
  A100: #bcff8d,
  A200: #9dff5a,
  A400: #7fff27,
  A700: #70ff0e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$brand-theme-primary: mat-palette($mat-primary);
$brand-theme-accent: mat-palette($mat-secondary);
$brand-theme-warn: mat-palette($mat-red);

$brand-theme: mat-dark-theme(
  (
    color: (
      primary: $brand-theme-primary,
      accent: $brand-theme-accent,
      warn: $brand-theme-warn,
    ),
  )
);

@include angular-material-theme($brand-theme);

$primary: #0B4060;
$secondary: #40C09E;
$tertiary: #E523C0;
$error :#f75a3d;

$primary-btn: linear-gradient(97.06deg, #2B826C 40.3%, #3ABA98 107.32%);
$primary-btn-text: #2B826C;

:root {
  // Root-size
  --root-size: 16px;
  --base-icon-size: 2rem;

  // Header
  --header-height: 4.375rem;
  --header-inner-spacing: 0rem 1rem;
  --header-icon-size: var(--base-icon-size);
  --header-title-size: 0.875rem;
  --header-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%),
    0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
  --brand-height: 2.8125rem;

  // Sidenav
  --sidenav-width: 15.625rem;
  --sidenav-closed-width: 4rem;
  --sidenav-shadow: 0 2px 20px 0 rgba($color: #000000, $alpha: 0.1);
  --sidenav-nav-height: 3.5rem;
  --sidenav-inner-spacing: 0rem 1rem 0 3rem;
  --sidenav-icon-size: var(--base-icon-size);
  --sidenav-text-size: 0.875rem;
  --sidenav-subnav-height: 2.75rem;
  --sidenav-subnav-indent: 3rem;
  --sidenav-subnav-text-size: calc(var(--sidenav-text-size) - 1px);

  // Dashboard
  --dashboard-title-size: 1.5rem;
  --dashboard-inner-spacing: 1rem;

  // Tooltip
  --tooltip-text-size: 0.75rem;

  // Scrollbar
  --scrollbar-width: 5px;

  // iframe
  --iframe-radius: 0.75rem;
  --iframe-border: none;
  --iframe-inner-spacing: 0;

  // Modal
  --modal-outer-spacing: 1rem;
  --modal-inner-spacing: 1rem;

  // Hero Content
  --hero-title-size: 3rem;
  --hero-text-size: 1rem;
  --hero-brand-size: 4.4375rem;

  // Loader
  --loader-size: 50px;

  // form
  --label-text-size: 0.75rem;

  --client-name-text-color: #40C09E;
}
