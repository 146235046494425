
.see-more-accordian {
    display: block;
    margin-bottom: 1rem;
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    // padding: 0 1rem;
    .mat-expansion-panel {
        background-color: transparent;
        box-shadow: none;
        .mat-expansion-panel-header {
            padding: 0 1rem;
        }
        .exp-label {
            margin: 0 1rem 0 auto;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.1px;
        }
    }
    .mat-expansion-panel-body {
        padding: 0 16px 16px;
        p {
            font-weight: 400;
        }
    }
    .mat-expansion-panel-header-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1px;
    }
    .mat-expansion-indicator::after {
        padding: 5px;
        position: relative;
        top: -4px;
    }
}

// File List
.files-list {
    padding: 0;
    p {
        font-size: 0.875rem;
    }
    a{
        cursor: pointer;
    }
}
// Note
.note-wrapper {
    margin: 1rem 0 0.5rem;
    padding: 1.25rem;
    border: 1px solid $tertiary;
    color: $secondary;
    border-radius: 0.5rem;
    margin-top: auto;
    h4 {
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 1rem;
        color: $tertiary;
    }
    p {
        font-size: 0.875rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}