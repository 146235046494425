.flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.justify-start {
    justify-content: start;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: end;
}
.justify-content-between {
    justify-content: space-between;
}

.align-items-start {
    align-items: start;
}
.align-items-center {
    align-items: center;
}
.align-items-end {
    align-items: end;
}