@for $value from 0 through 100 {
    // padding
    .pt-#{$value} {
        padding-top: $value * 0.25rem;
    }
    .pl-#{$value} {
        padding-left: $value * 0.25rem;
    }
    .pr-#{$value} {
        padding-right: $value * 0.25rem;
    }
    .pb-#{$value} {
        padding-bottom: $value * 0.25rem;
    }
    // Margin
    .mt-#{$value} {
        margin-top: $value * 0.25rem;
    }
    .ml-#{$value} {
        margin-left: $value * 0.25rem;
    }
    .mr-#{$value} {
        margin-right: $value * 0.25rem;
    }
    .mb-#{$value} {
        margin-bottom: $value * 0.25rem;
    }
}