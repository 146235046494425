.popover {
    position: relative;
    .info-icon {
        width: auto;
        height: auto;
        font-size: 16px;
        margin-left: 0.25rem;
    }
    .popover-container {
        position: absolute;
        top: -0.75rem;
        left: 2rem;
        background-color: #DDDDDD;
        color: #222222;
        @include radius(10px);
        padding: 1.25rem 1rem;
        display: none;
        z-index: 10;
        min-width: 22rem;
        &::before {
            position: absolute;
            content: '';
            top: 0.75rem;
            left: -0.75rem;
            border-top: 0.5rem solid transparent;
            border-right: 0.75rem solid #DDDDDD;
            border-bottom: 0.5rem solid transparent;
        }
        @media screen and (max-device-width: 420px), screen and (max-width: 767px) {
            min-width: 15rem;
        }
    }
    &:hover {
        .popover-container {
            display: block;
        }
    }
}
