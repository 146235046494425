.dashboard-wrapper {
  height: calc(100% - var(--header-height));
}

.dashboard-content {
  padding: var(--dashboard-inner-spacing);
  width: 100%;
  padding-bottom: 0;
  h3 {
    font-size: var(--dashboard-title-size);
    font-weight: 300;
    margin-bottom: 1rem;
  }
}

.iframe-content {
  @include radius(var(--iframe-radius));
  padding: var(--iframe-inner-spacing);
  overflow: hidden;

  iframe {
    border: var(--iframe-border);
  }
}

.menu-content {
  @include radius(var(--iframe-radius));
}
