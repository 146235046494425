.custom-tab{
    .mat-tab-label {
      height: 3rem;
      padding: 0 0.75rem;
    } 
    .mat-tab-label{
      font-weight: 400;;
    }
    .mat-tab-label{
      font-weight: 400;;
    }
    .mat-tab-group.mat-primary .mat-ink-bar, 
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
      background-color: $secondary;
  
    }
  }