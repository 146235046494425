.read-only {
    margin-bottom: 1rem;
    p {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0.25rem;
    }
    h4 {
        font-size: 0.875rem;
        font-weight: 500;
    }
}